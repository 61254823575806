<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Start Time -->
        <template #cell(startDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.startDate | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- End Time -->
        <template #cell(endDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.endDate | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
            <b-dropdown class="mx-1" right text="İşlemler" variant="flat-primary">
                <b-dropdown-item variant="flat-primary" @click="openExamQuestion(data.item)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="LinkIcon" class="mr-50" />
                    <span class="align-middle">Sorular</span>
                </b-dropdown-item>
                <b-dropdown-item variant="flat-primary" @click="editExam(data.item)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">Düzenle</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteTest(data.item.id)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span class="align-middle">Sil</span>
                </b-dropdown-item>
            </b-dropdown>
        </template>

    </b-table>

    <!--Open Add Exam-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addExamPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Test Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Test Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addExamRequest.title" />
                </b-form-group>
                <b-form-group label="Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="addExamRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i',locale:'en'}" />
                </b-form-group>
                <b-form-group label="Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="addExamRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i',locale:'en'}" />
                </b-form-group>
                <b-form-group label="Test Süresi" label-for="from">
                    <b-form-input name="firstName" id="name" type="number" v-model="addExamRequest.duration" :disabled="addExamRequest.isIndefinite"/>
                    <b-form-checkbox v-model="addExamRequest.isIndefinite">
                        Süresiz Test
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Qr Kod" label-for="name">
                    <b-input-group>
                        <input type="file" style="display:none" ref="fileSelect" @change="handleImage" />
                        <b-form-input name="firstName" id="name" type="text" v-model="addExamRequest.qrCode" />
                        <b-input-group-append>
                            <b-button variant="outline-primary" @click="qrSelect">
                                YÜKLE
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addExam">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <!--Open Edit Exam-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editExamPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Test Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Test Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editExamRequest.title" />
                </b-form-group>
                <b-form-group label="Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="editExamRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i',locale:'en'}" />
                </b-form-group>
                <b-form-group label="Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="editExamRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'm-d-Y H:i',locale:'en'}" />
                </b-form-group>
                <b-form-group label="Test Süresi" label-for="from">
                    <b-form-input name="firstName" id="name" type="number" v-model="editExamRequest.time" :disabled="editExamRequest.isIndefinite"/>
                    <b-form-checkbox v-model="editExamRequest.isIndefinite">
                        Süresiz Test
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Qr Kod" label-for="name">
                    <b-input-group>
                        <input type="file" style="display:none" ref="fileSelect" @change="handleImage" />
                        <b-form-input name="firstName" id="name" type="text" v-model="editExamRequest.qrCode" />
                        <b-input-group-append>
                            <b-button variant="outline-primary" @click="qrSelect">
                                YÜKLE
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editExamRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateExam">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="100%" shadow backdrop right v-model="openQuestionPanel">
        <template #footer>
            <div class="d-flex text-light align-items-center px-3 py-1">
                <strong class="mr-auto"></strong>
                <b-button variant="success" class="mr-2" type="submit" @click="eventPreview = !eventPreview">
                    Düzeni Kaydet
                </b-button>
                <b-button variant="primary" class="mr-2" type="submit" @click="addQuestionPanel=true">
                    Yeni Soru Ekle
                </b-button>
            </div>
        </template>
        <question-table :testId="selectedExam.id" v-if="openQuestionPanel" :event="eventPreview" :saveEvent="saveEvent" />
    </b-sidebar>

    <!--Add Test Question --->
    <b-modal title="Yeni Soru Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addQuestionPanel" @ok="addTestQuestion">
        <b-form>
            <b-form-group label="Soru Resmi ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="addQuestionRequest.questionFile" />
            </b-form-group>
            <b-form-group label="Çözüm Videosu ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="addQuestionRequest.solutionFile" />
            </b-form-group>
            <b-form-group label="Doğru Cevap ? " label-for="vue-select">
                <v-select id="vue-select" v-model="addQuestionRequest.isTrue" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(answers) => answers.key" label="key" :options="answers" />
            </b-form-group>
        </b-form>
    </b-modal>

    <b-modal size="xl" title="Pdf Test Oluşturma" ok-title="Ekle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addPdfExamPanel" @ok="addPdfExam">
        <b-row>
            <b-col lg="8" cols="12" order="1" order-lg="1">
                <iframe src="https://www.google.com/" width="100%" />
            </b-col>
            <b-col lg="4" cols="12" order="1" order-lg="1">
                <b-card>

                </b-card>
            </b-col>
        </b-row>
    </b-modal>
    <input ref="pdfSelector" accept="application/pdf" type="file" @change="pdfSelect" style="display:none">

</b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuestionTable from "./PageComponent/ExamQuestionTable"
import flatPickr from 'vue-flatpickr-component'
import axios from "axios"
export default {
    components: {
        BRow,
        BCol,
        flatPickr,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
        BInputGroupAppend,
        "question-table": QuestionTable,
        BDropdown,
        BDropdownItem
    },
    data() {
        return {
            answers: [{ key: 'A' }, { key: 'B' }, { key: 'C' }, { key: 'D' }, { key: 'E' }],
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'title', label: 'Başlık' },
                { key: 'startDate', label: 'Başlangıç Tarihi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'actions', label: 'Detaylar' },
            ],
            users: [],
            addExamPanel: false,
            editExamPanel: false,
            openQuestionPanel: false,
            addExamRequest: {
                videoGroupId: 0,
                title: '',
                startDate: '',
                endDate: '',
                qrCode: '',
                duration: 0,
                isIndefinite: false
            },
            editExamRequest: {
                testId: 0,
                title: '',
                startDate: '',
                endDate: '',
                time: 0,
                qrCode: '',
                isActive: false,
                isIndefinite: false
            },
            questions: [],
            selectedExam: {},
            addQuestionPanel: false,
            addQuestionRequest: {
                testId: 0,
                questionFile: '',
                solutionFile: '',
                isTrue: 'A'
            },
            gains: [],
            eventPreview: null,
            saveEvent: null,
            addPdfExamPanel: false
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addExamPanel = true;
        });
        this.$root.$on('AddPdfClick', () => {
            this.$refs.pdfSelector.click();
        });
    },
    created() {
        this.getData();
        this.getGains();
    },
    methods: {
        addPdfExam() {
            //console.log("AddPdfExam");
        },
        pdfSelect(event) {
            //console.log(event.target.files);
            this.addPdfExamPanel = true;
        },
        async getGains() {
            var gains = await this.$http.get("Teacher/Gains/" + this.$route.params.videoGroupId);
            this.gains = gains.data.data;
        },
        async getData() {
            var users = await this.$http.get("Teacher/Test/" + this.$route.params.videoGroupId);
            this.users = users.data.data;
        },
        addExam() {
            this.addExamRequest.videoGroupId = this.$route.params.videoGroupId;
            this.addExamRequest.duration = parseInt(this.addExamRequest.duration);
            var request = this.addExamRequest;
            //console.log(request);
            this.$http.post("Teacher/AddTest", request).then((data) => {
                    this.getData();
                    this.addExamPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Test Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        handleImage(e) {
            if (e.target.files == null)
                return;
            const selectedImage = e.target.files[0];
            var formData = new FormData();
            formData.append("file", selectedImage);
            this.$http.post("Report/QrReader", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(x => {
                this.addExamRequest.qrCode = x.data;
                this.editExamRequest.qrCode = x.data;
            })
        },
        qrSelect() {
            this.$refs.fileSelect.click();
        },
        updateExam() {
            this.editExamRequest.time = parseInt(this.editExamRequest.time);
            var request = this.editExamRequest;
            this.$http.put("Teacher/UpdateTest", request).then((data) => {
                    this.getData();
                    this.editExamPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Test Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        editExam(data) {
            this.editExamRequest = {
                testId: data.id,
                title: data.title,
                startDate: data.startDate,
                endDate: data.endDate,
                isActive: data.isActive,
                qrCode: data.qrCode,
                time: data.time,
                isIndefinite: data.isIndefinite
            }
            this.editExamPanel = true;
        },
        async openExamQuestion(data) {
            this.selectedExam = data;
            this.openQuestionPanel = true;
        },
        async addTestQuestion() {
            this.addQuestionRequest.testId = this.selectedExam.id;
            await this.$http.post("Teacher/AddTestQuestion", this.addQuestionRequest);
            this.saveEvent = !this.saveEvent;
        },
        deleteTest(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteTest/" + data).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Test Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
